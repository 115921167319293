import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

export const Container = styled.div`
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  white-space: pre-line;
  min-height: 100vh;
`;

export const Viewport = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${Colors.neutralGray300};
`;
