import { setDiscordAccount } from 'api/firebase-rest-api';
import { FirebaseAuthContext } from 'context/firebaseAuthContext';
import { getIdToken } from 'firebase/auth';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Styled from './discordAccount.style';

function DiscordAccount(): ReactElement {
  const DiscordRedirectUrl = 'https://admin.nomadinseoul.com/discord-account';
  let navigate = useNavigate();
  let location = useLocation();
  const user = useContext(FirebaseAuthContext);

  const urlParams = new URLSearchParams(location.search);
  const code = urlParams.get('code');

  const [discordUserData, setDiscordUserData] = useState<DiscordUserDto | null>(null);

  useEffect(() => {
    if (user && code) {
      (async () => {
        const token = await getIdToken(user);

        const response = await setDiscordAccount(user, {
          code: code,
          redirectUri: DiscordRedirectUrl,
        });
        setDiscordUserData(response.user);
        window.opener.location.reload();
      })();
    }
  }, [user]);
  return <Styled.Container></Styled.Container>;
}

export default DiscordAccount;
