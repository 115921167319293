import EmailIcon from 'img/Icons/EmailIcon.png';
import GoogleIcon from 'img/Icons/GoogleIcon.png';
import CloseIconBlack from 'img/Icons/CloseIconBlack.png';
import CloseIconWhite from 'img/Icons/CloseIconWhite.png';
import Discord from 'img/Icons/discord-mark-blue.png';

export const ProviderIcons: { [key in ProviderIdType]: '*.png' } = {
  'google.com': GoogleIcon,
  password: EmailIcon,
};

export const Icons = {
  CloseWhite: CloseIconWhite,
  CloseBlack: CloseIconBlack,
  Discord,
};
