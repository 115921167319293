import { getUser, getUsers, modifyUser } from 'api/firebase-rest-api';
import { Colors } from 'common';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { db } from 'services/firebase';
import { child, get, ref } from 'firebase/database';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import * as Styled from './manageEvents.style';

import { Icons, ProviderIcons } from 'common/images';
import AuthFirst from 'components/authFirst';
import Header from 'components/header';
import dayjs from 'dayjs';
import { FirebaseAuthContext } from 'context/firebaseAuthContext';

function ManageEvents(): ReactElement {
  const user = useContext(FirebaseAuthContext);
  const [events, setEvents] = useState<{ [id: string]: EventDto }>({});

  useEffect(() => {
    if (user) {
      const dbRef = ref(db);
      (async () => {
        try {
          const snapshot = await get(child(dbRef, 'events'));
          if (snapshot.exists()) {
            setEvents(snapshot.val());
          }
        } catch (error) {
          alert('에러 발생');
        }
      })();
    }
  }, [user]);

  return (
    <>
      {user ? (
        <>
          <Header />
          <Styled.Container>
            <Styled.Viewport>
              <Styled.EventContainer>
                <Styled.EventInfoGrid>
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralBlack} textAlign="left">
                    title
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralBlack} textAlign="left">
                    author
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralBlack} textAlign="left">
                    date
                  </Typo.IBM_SemiBold>
                </Styled.EventInfoGrid>

                {Object.keys(events).map((id) => {
                  const data = events[id];
                  return <EventInfo id={id} key={id} data={data} />;
                })}
              </Styled.EventContainer>
            </Styled.Viewport>
          </Styled.Container>
        </>
      ) : (
        <AuthFirst />
      )}
    </>
  );
}

function EventInfo(props: { id: string; data: EventDto }): ReactElement {
  const { id, data } = props;

  return (
    <Styled.EventInfoGrid>
      <Typo.IBM_Regular fontSize={14} textAlign="left" color={Colors.neutralBlack}>
        {data.title}
      </Typo.IBM_Regular>
      <Typo.IBM_Regular fontSize={14} textAlign="left" color={Colors.neutralBlack}>
        {data.author.username}
      </Typo.IBM_Regular>
      <Typo.IBM_Regular fontSize={14} textAlign="left" color={Colors.neutralBlack}>
        {dayjs(data.date).format('YYYY-MM-DD')}
      </Typo.IBM_Regular>
    </Styled.EventInfoGrid>
  );
}

export default ManageEvents;
