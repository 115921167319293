import { Colors } from 'common';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import AuthFirst from 'components/authFirst';
import Header from 'components/header';
import { FirebaseAuthContext } from 'context/firebaseAuthContext';
import React, { ReactElement, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Styled from './manageChallenges.style';

function ManageChallenges(): ReactElement {
  let navigate = useNavigate();
  let location = useLocation();
  const user = useContext(FirebaseAuthContext);

  const DiscordOauth2Url =
    'https://discord.com/api/oauth2/authorize?client_id=1027034838194196483&redirect_uri=https%3A%2F%2Fadmin.nomadinseoul.com%2Fmanage-challenges&response_type=code&scope=identify%20guilds%20email';

  useEffect(() => {
    if (user === undefined) return;

    if (user) {
    }
  }, [user]);

  return (
    <>
      {user ? (
        <>
          <Header />
          <Styled.Container>
            <Styled.Viewport>
              <Common.SizedBoxH height={60} />
              <Typo.Swagger fontSize={20} color={Colors.neutralWhite} textAlign="cetner">
                디스코드 설정
              </Typo.Swagger>
              <Common.SizedBoxH height={30} />
            </Styled.Viewport>
          </Styled.Container>
        </>
      ) : (
        <AuthFirst />
      )}
    </>
  );
}

export default ManageChallenges;
