import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { browserLocalPersistence, GoogleAuthProvider, setPersistence, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from 'services/firebase';
import * as Styled from './authFirst.style';

import { Colors } from 'common';
import { ProviderIcons } from 'common/images';

function AuthFirst(): ReactElement {
  let navigate = useNavigate();

  const [signinEmail, setSigninEmail] = useState<string>('');
  const [signinPassword, setSigninPassword] = useState<string>('');

  const signWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    auth.languageCode = 'KR';
    try {
      const credential = await signInWithPopup(auth, provider);
      navigate(0);
    } catch (error) {
      console.log(error);
    }
  };

  const signin = async () => {
    const emailValue = (signinEmail ?? '').trim();
    const passwordValue = (signinPassword ?? '').trim();

    if (!emailValue) {
      alert('이메일을 입력해주세요.');
      return;
    }

    if (!passwordValue) {
      alert('비밀번호를 입력해주세요.');
      return;
    }

    try {
      await setPersistence(auth, browserLocalPersistence);
      const userCredential = await signInWithEmailAndPassword(auth, emailValue, passwordValue);
      navigate(0);
    } catch (error) {
      console.log(error);
      alert('에러 발생.');
      return;
    } finally {
      setSigninEmail('');
      setSigninPassword('');
    }
  };

  return (
    <Styled.Container>
      <Styled.Viewport>
        <Typo.IBM_SemiBold fontSize={28} color={Colors.neutralBlack}>
          로그인
        </Typo.IBM_SemiBold>

        <Common.SizedBoxH height={40} />

        <Common.RoundedFilledButton width={260} height={45} backgroundColor={Colors.neutralGray300} onClick={() => signWithGoogle()}>
          <Common.FlexRow alignItems="center" justifyContent="center">
            <img src={ProviderIcons['google.com']} style={{ width: '21px', height: '21px' }} />
            <Common.SizedBoxW width={10} />
            <Typo.IBM_Regular fontSize={14} color={Colors.neutralWhite}>
              구글 계정으로 로그인
            </Typo.IBM_Regular>
          </Common.FlexRow>
        </Common.RoundedFilledButton>

        <Common.SizedBoxH height={24} />

        <Common.FlexRow alignItems="center" justifyContent="center">
          <Styled.Dash />
          <Common.SizedBoxW width={12} />
          <Typo.IBM_Regular fontSize={14} color={Colors.neutralBlack}>
            또는 이메일 로그인
          </Typo.IBM_Regular>
          <Common.SizedBoxW width={12} />
          <Styled.Dash />
        </Common.FlexRow>

        <Common.SizedBoxH height={24} />

        <Common.RoundedFilledInput
          value={signinEmail}
          onChange={(e) => setSigninEmail(e.target.value)}
          width={260}
          height={45}
          placeholder="이메일"
        />
        <Common.SizedBoxH height={12} />
        <Common.RoundedFilledInput
          value={signinPassword}
          onChange={(e) => setSigninPassword(e.target.value)}
          width={260}
          height={45}
          type="password"
          placeholder="비밀번호"
        />

        <Common.SizedBoxH height={24} />

        <Common.RoundedFilledButton width={260} height={45} color={Colors.primary500} onClick={() => signin()}>
          <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite}>
            로그인
          </Typo.IBM_SemiBold>
        </Common.RoundedFilledButton>

        <Common.SizedBoxH height={12} />

        <Typo.IBM_Regular color={Colors.neutralGray200} fontSize={12} textAlign="right" width={256}>
          이메일/비밀번호 찾기
        </Typo.IBM_Regular>
      </Styled.Viewport>
    </Styled.Container>
  );
}

export default AuthFirst;
