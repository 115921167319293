import { Colors } from 'common';
import React, { ReactElement, useEffect, useState } from 'react';
import * as Styled from './editEvent.style';

import Header from 'components/header';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtmlPuri from 'draftjs-to-html';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { storage } from 'services/firebase';

function EditEvent(): ReactElement {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [createdAt, setCreatedAt] = useState<Date>();
  const [location, setLocation] = useState('');

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [htmlPuri, setHtmlPuri] = useState('');

  function uploadImageCallBack(file: File) {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `/events/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100); // update progress
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url);
            resolve({ data: { link: url } });
          });
        },
      );
    });
  }

  useEffect(() => {
    const html = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()));
    setHtmlPuri(html);
    console.log(html);
  }, [editorState]);

  return (
    <>
      <Header />
      <Styled.Container>
        <Styled.Viewport>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorStyle={{
              backgroundColor: Colors.neutralWhite,
              padding: '5px 20px',
              fontFamily: 'IBMPlexSansKR',
              height: 'calc(100vh - 240px)',
              lineHeight: 1,
            }}
            onEditorStateChange={setEditorState}
            hashtag={{
              separator: ' ',
              trigger: '#',
            }}
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              fontFamily: {
                options: ['IBMPlexSansKR', 'Swagger'],
                history: { inDropdown: true },
              },
              image: {
                uploadCallback: uploadImageCallBack,
                alt: { present: false, mandatory: false },
              },
            }}
          />
          <div dangerouslySetInnerHTML={{ __html: htmlPuri }}></div>
        </Styled.Viewport>
      </Styled.Container>
    </>
  );
}

export default EditEvent;
