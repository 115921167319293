import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import React, { ReactElement } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from 'services/firebase';
import * as Styled from './header.style';

import { Colors } from 'common';
import { signOut } from 'firebase/auth';
import Logo from 'img/Common/Logo.png';

function Header(): ReactElement {
  let navigate = useNavigate();
  return (
    <Styled.Container>
      <Styled.Viewport>
        <Link to="/">
          <Common.SizedImage src={Logo} width={45} height={45} />
        </Link>

        <Common.SizedBoxW width={60} />

        <Link to="/manage-users">
          <Typo.IBM_Regular fontSize={16} color={Colors.neutralBlack}>
            유저 관리
          </Typo.IBM_Regular>
        </Link>

        <Common.SizedBoxW width={60} />
        <Link to="/manage-challenges">
          <Typo.IBM_Regular fontSize={16} color={Colors.neutralBlack}>
            챌린지 관리
          </Typo.IBM_Regular>
        </Link>

        <Common.SizedBoxW width={60} />
        <Link to="/manage-events">
          <Typo.IBM_Regular fontSize={16} color={Colors.neutralBlack}>
            이벤트 관리
          </Typo.IBM_Regular>
        </Link>

        <Common.SizedBoxW width={60} />
        <Link to="/manage-agits">
          <Typo.IBM_Regular fontSize={16} color={Colors.neutralBlack}>
            아지트 관리
          </Typo.IBM_Regular>
        </Link>

        <Common.Span />

        <Common.RoundedFilledButton
          width={124}
          height={51}
          onClick={async () => {
            await signOut(auth);
            navigate('/');
          }}
        >
          <Typo.IBM_Regular fontSize={14} color={Colors.neutralWhite}>
            로그아웃
          </Typo.IBM_Regular>
        </Common.RoundedFilledButton>
      </Styled.Viewport>
    </Styled.Container>
  );
}

export default Header;
