export default {
  // Primary
  primary100: '#ffbc80',
  primary200: '#ff9f45',
  primary300: '#f76e11',
  primary400: '#ff5e29',
  primary500: '#fc4f4f',
  // Secondary
  secondary100: '#ff4848',
  secondary200: '#ffd371',
  secondary300: '#c2ffd9',
  secondary400: '#9ddac6',
  // Neutral
  neutralBlack: '#000000',
  neutralWhite: '#ffffff',
  neutralGray100: '#eef3f6',
  neutralGray200: '#8d9194',
  neutralGray300: '#26282c',
};
