import React, { ReactElement, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import * as Components from 'components';
import * as Styled from './App.style';
import { FirebaseAuthProvider } from 'context/firebaseAuthContext';
import { createGlobalStyle } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import Swagger from 'fonts/SDSwagger/SDSwagger.otf';
import IBMRegular from 'fonts/IBM_Plex_Sans_KR/IBMPlexSansKR-Regular.ttf';
import IBMSemiBold from 'fonts/IBM_Plex_Sans_KR/IBMPlexSansKR-SemiBold.ttf';

import 'react-toastify/dist/ReactToastify.css';

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: Swagger;
  font-display: fallback;
  src:url(${Swagger}) format('opentype'); 
}

@font-face {
  font-family: IBMPlexSansKR;
  src:url(${IBMRegular}) format('truetype'); 
  font-weight: normal;
}

@font-face {
  font-family: IBMPlexSansKR;
  src:url(${IBMSemiBold}) format('truetype'); 
  font-weight: 600;
}

body {
    font-family: IBMPlexSansKR, Swagger;
  }
`;

export default function App(): ReactElement {
  return (
    <Styled.Container>
      <GlobalStyle />
      <ToastContainer />
      <FirebaseAuthProvider>
        <BrowserRouter>
          <Styled.Viewport>
            <Routes>
              <Route element={<Components.Main />} path="/" />
              <Route element={<Components.ManageUsers />} path="manage-users" />
              <Route element={<Components.ManageChallenges />} path="manage-challenges" />
              <Route element={<Components.ManageEvents.ManageEvents />} path="manage-events" />
              <Route element={<Components.ManageEvents.EditEvent />} path="manage-events/edit" />
              <Route element={<Components.DiscordAccount />} path="discord-account" />
            </Routes>
          </Styled.Viewport>
        </BrowserRouter>
      </FirebaseAuthProvider>
    </Styled.Container>
  );
}
