import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  background-color: ${Colors.neutralGray300};
  min-height: 100vh;
  padding: 80px 0 0 0;
`;

export const Viewport = styled.div`
  width: 1440px;
  padding: 40px 60px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const UserInfoGrid = styled.div`
  height: 30px;
  display: grid;
  // email providers username membership points level uid btn
  grid-template-columns: 1fr 100px 180px 120px 80px 80px 1fr 60px 80px;
  grid-gap: 5px;
  align-items: center;
  background-color: transparent;
`;

export const UserDetail = styled.div`
  width: 500px;

  background: ${Colors.neutralGray300};
  border-radius: 20px;
  border: 2px solid ${Colors.neutralWhite};

  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 12px;
`;

export const UserDetailGeneralInfo = styled.div`
  height: 35px;
  display: grid;
  grid-template-columns: 100px auto 40px;
  grid-gap: 10px;
  align-items: center;
`;
