import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  background-color: ${Colors.neutralWhite};
`;

export const Viewport = styled.div`
  width: 1440px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Dash = styled.div`
  width: 54px;
  height: 0px;

  border: 0.5px dashed #26282c;
`;
