import { getUser, getUsers, modifyUser } from 'api/firebase-rest-api';
import { Colors } from 'common';
import * as Common from 'common/commonStyle';
import * as Typo from 'common/typography';
import { child, get, ref } from 'firebase/database';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { db } from 'services/firebase';
import * as Styled from './manageUsers.style';

import { Icons, ProviderIcons } from 'common/images';
import AuthFirst from 'components/authFirst';
import Header from 'components/header';
import dayjs from 'dayjs';
import { FirebaseAuthContext } from 'context/firebaseAuthContext';
import { toast } from 'react-toastify';

const MembershipOptions: { value?: MembershipType; label: string }[] = [
  { value: undefined, label: '없음' },
  { value: 'monthly', label: '월간' },
  { value: 'annually', label: '연간' },
  { value: 'permanent', label: '평생' },
];

function ManageUsers(): ReactElement {
  const user = useContext(FirebaseAuthContext);
  const [users, setUsers] = useState<FirebaseUserDto[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>();

  const [searchParam, setSearchParam] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [selectedUser, setSelectedUser] = useState<FirebaseUserDto | null>(null);
  const [selectedUserData, setSelectedUserData] = useState<UserDto | null>(null);
  const [selectedUserDiscordData, setSelectedUserDiscordData] = useState<DiscordUserDto | null>(null);

  const [newUserData, setNewUserData] = useState<UserDto | null>(null);

  const limit = 30;

  useEffect(() => {
    if (user) {
      getUserData();
    }
  }, [user]);

  useEffect(() => {
    if (!selectedUser) {
      setSelectedUserData(null);
      setNewUserData(null);
      return;
    }

    const dbRef = ref(db);
    (async () => {
      try {
        const snapshot = await get(child(dbRef, `users/${selectedUser.uid}`));
        if (snapshot.exists()) {
          const data = snapshot.val();
          setSelectedUserData(data);
          setNewUserData(data);
        }
      } catch (error) {
        alert('에러 발생');
      }
    })();
  }, [selectedUser]);

  async function getUserData() {
    if (!user) return;

    setIsLoading(true);

    const searchParamValue = (searchParam ?? '').trim();

    if (searchParamValue) {
      const response = await getUser(user, searchParamValue);

      setUsers([response]);
      setNextPageToken(undefined);
    } else {
      const response = await getUsers(user, {
        limit: limit,
        nextPageToken: nextPageToken,
      });

      setUsers((current) => {
        return current.concat(response.users);
      });
      setNextPageToken(response.pageToken);
    }

    setIsLoading(false);
  }

  async function modifyUserData() {
    if (!user || !selectedUser || !newUserData) return;
    if (selectedUserData === newUserData) return;

    try {
      const response = await modifyUser(user, {
        uid: selectedUser.uid,
        data: newUserData,
      });

      setSelectedUserData(response);
      setNewUserData(response);
    } catch (error) {
      alert('에러 발생');
    }
  }

  function copyText(text: string) {
    const textarea = document.createElement('textarea');
    textarea.value = text;

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    toast('Copied!', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  }

  return (
    <>
      {user ? (
        <>
          <Header />
          <Styled.Container>
            <Styled.Viewport>
              <Common.FlexRow width={'100%'}>
                <Common.RoundedFilledInput
                  value={searchParam}
                  onChange={(e) => setSearchParam(e.target.value)}
                  width={'100%'}
                  height={30}
                  placeholder="Search by email address or uid"
                />
                <Common.SizedBoxW width={20} />
                <Common.RoundedFilledButton width={80} height={30} backgroundColor={Colors.neutralWhite}>
                  <Typo.IBM_Regular
                    fontSize={14}
                    textAlign="center"
                    color={Colors.neutralBlack}
                    onClick={() => {
                      setUsers([]);
                      getUserData();
                    }}
                  >
                    검색
                  </Typo.IBM_Regular>
                </Common.RoundedFilledButton>
              </Common.FlexRow>

              <Common.SizedBoxH height={20} />

              <Styled.UsersContainer>
                <Styled.UserInfoGrid>
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite} textAlign="left">
                    email
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite} textAlign="left">
                    providers
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite} textAlign="left">
                    username
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite} textAlign="left">
                    membership
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite} textAlign="left">
                    points
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite} textAlign="left">
                    level
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite} textAlign="left">
                    uid
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_SemiBold fontSize={14} color={Colors.neutralWhite} textAlign="left">
                    discord
                  </Typo.IBM_SemiBold>
                </Styled.UserInfoGrid>

                {users.map((e, i) => (
                  <UserInfo data={e} key={e.uid} onDetail={() => setSelectedUser(e)} />
                ))}
              </Styled.UsersContainer>

              {nextPageToken && !isLoading && (
                <>
                  <Common.SizedBoxH height={20} />

                  <Common.NoOpacityButton height={20} onClick={() => getUserData()}>
                    <Typo.IBM_Regular fontSize={14} color={Colors.neutralWhite} textAlign="center">
                      + 더 보기
                    </Typo.IBM_Regular>
                  </Common.NoOpacityButton>
                </>
              )}
            </Styled.Viewport>
          </Styled.Container>
          {selectedUser && (
            <Common.ModalBackground>
              <Styled.UserDetail>
                <Common.FlexRow alignItems="center">
                  <Typo.IBM_SemiBold fontSize={20} textAlign="left" color={Colors.neutralWhite}>
                    계정 정보
                  </Typo.IBM_SemiBold>
                  <Common.Span />
                  <Common.NoOpacityButton
                    width={30}
                    height={30}
                    onClick={() => {
                      setSelectedUser(null);
                      setSelectedUserData(null);
                      setSelectedUserDiscordData(null);
                    }}
                  >
                    <Common.SizedImage width={20} height={20} src={Icons.CloseWhite} />
                  </Common.NoOpacityButton>
                </Common.FlexRow>

                <Common.SizedBoxH height={10} />

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    UID
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_Regular fontSize={14} color={Colors.neutralWhite} textAlign="right">
                    {selectedUser.uid}
                  </Typo.IBM_Regular>
                  <Common.RoundedFilledButton
                    width={40}
                    height={25}
                    backgroundColor={Colors.neutralWhite}
                    onClick={() => {
                      copyText(selectedUser.uid);
                    }}
                  >
                    <Typo.IBM_Regular fontSize={14} color={Colors.neutralBlack}>
                      복사
                    </Typo.IBM_Regular>
                  </Common.RoundedFilledButton>
                </Styled.UserDetailGeneralInfo>

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    Email
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_Regular fontSize={14} color={Colors.neutralWhite} textAlign="right">
                    {selectedUser.email}
                  </Typo.IBM_Regular>
                  <Common.RoundedFilledButton
                    width={40}
                    height={25}
                    backgroundColor={Colors.neutralWhite}
                    onClick={() => {
                      copyText(selectedUser.email);
                    }}
                  >
                    <Typo.IBM_Regular fontSize={14} color={Colors.neutralBlack}>
                      복사
                    </Typo.IBM_Regular>
                  </Common.RoundedFilledButton>
                </Styled.UserDetailGeneralInfo>

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    Providers
                  </Typo.IBM_SemiBold>
                  <Common.FlexRow width="100%" justifyContent="flex-end">
                    {selectedUser.providerData.map((e) => (
                      <Common.SizedImage src={ProviderIcons[e.providerId]} width={20} height={20} key={selectedUser.uid + e.providerId} />
                    ))}
                  </Common.FlexRow>
                </Styled.UserDetailGeneralInfo>

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    Created
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_Regular fontSize={14} color={Colors.neutralWhite} textAlign="right">
                    {dayjs(selectedUser.metadata.creationTime).format('YYYY-MM-DD')}
                  </Typo.IBM_Regular>
                </Styled.UserDetailGeneralInfo>

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    Signed In
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_Regular fontSize={14} color={Colors.neutralWhite} textAlign="right">
                    {dayjs(selectedUser.metadata.lastSignInTime).format('YYYY-MM-DD')}
                  </Typo.IBM_Regular>
                </Styled.UserDetailGeneralInfo>

                <Common.SizedBoxH height={20} />

                <Typo.IBM_SemiBold fontSize={20} textAlign="left" color={Colors.neutralWhite}>
                  유저 정보
                </Typo.IBM_SemiBold>

                <Common.SizedBoxH height={10} />

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    Username
                  </Typo.IBM_SemiBold>

                  <Common.RoundedFilled
                    width="100%"
                    height={30}
                    backgroundColor={selectedUserData?.username === newUserData?.username ? Colors.neutralWhite : Colors.primary100}
                  >
                    <Common.NoOpacityInput
                      width="100%"
                      height="100%"
                      fontSize={14}
                      textAlign="right"
                      color={Colors.neutralBlack}
                      value={newUserData?.username}
                      onChange={(e) =>
                        setNewUserData((data) => {
                          return { ...data, username: e.target.value };
                        })
                      }
                    />
                  </Common.RoundedFilled>
                </Styled.UserDetailGeneralInfo>

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    Point
                  </Typo.IBM_SemiBold>

                  <Common.RoundedFilled
                    width="100%"
                    height={30}
                    backgroundColor={selectedUserData?.point === newUserData?.point ? Colors.neutralWhite : Colors.primary100}
                  >
                    <Common.NoOpacityInput
                      width="100%"
                      height="100%"
                      fontSize={14}
                      textAlign="right"
                      color={Colors.neutralBlack}
                      value={newUserData?.point}
                      type="number"
                      onChange={(e) =>
                        setNewUserData((data) => {
                          return { ...data, point: parseInt(e.target.value) };
                        })
                      }
                    />
                  </Common.RoundedFilled>
                </Styled.UserDetailGeneralInfo>

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    Level
                  </Typo.IBM_SemiBold>

                  <Common.RoundedFilled
                    width="100%"
                    height={30}
                    backgroundColor={selectedUserData?.level === newUserData?.level ? Colors.neutralWhite : Colors.primary100}
                  >
                    <Common.NoOpacityInput
                      width="100%"
                      height="100%"
                      fontSize={14}
                      textAlign="right"
                      color={Colors.neutralBlack}
                      value={newUserData?.level}
                      type="number"
                      onChange={(e) =>
                        setNewUserData((data) => {
                          return { ...data, level: parseInt(e.target.value) };
                        })
                      }
                    />
                  </Common.RoundedFilled>
                </Styled.UserDetailGeneralInfo>

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    Exp
                  </Typo.IBM_SemiBold>

                  <Common.RoundedFilled
                    width="100%"
                    height={30}
                    backgroundColor={selectedUserData?.exp === newUserData?.exp ? Colors.neutralWhite : Colors.primary100}
                  >
                    <Common.NoOpacityInput
                      width="100%"
                      height="100%"
                      fontSize={14}
                      textAlign="right"
                      color={Colors.neutralBlack}
                      value={newUserData?.exp}
                      type="number"
                      onChange={(e) =>
                        setNewUserData((data) => {
                          return { ...data, exp: parseInt(e.target.value) };
                        })
                      }
                    />
                  </Common.RoundedFilled>
                </Styled.UserDetailGeneralInfo>

                <Common.SizedBoxH height={20} />

                <Typo.IBM_SemiBold fontSize={20} textAlign="left" color={Colors.neutralWhite}>
                  멤버십 정보
                </Typo.IBM_SemiBold>

                <Common.SizedBoxH height={10} />

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    멤버십 등급
                  </Typo.IBM_SemiBold>
                  <Select
                    options={MembershipOptions}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: 0,
                        width: '100%',
                        height: 30,
                        minHeight: 30,
                        fontSize: 14,
                        fontFamily: 'IBMPlexSansKR',
                        backgroundColor: `${
                          selectedUserData?.membership?.id === newUserData?.membership?.id ? Colors.neutralWhite : Colors.primary100
                        }`,
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: 0,
                        height: 30,
                        minHeight: 30,
                        fontSize: 14,
                        fontFamily: 'IBMPlexSansKR',
                        fontWeight: 'normal',
                      }),
                    }}
                    defaultValue={{ value: undefined, label: '없음' }}
                    value={MembershipOptions.find((e) => e.value === newUserData?.membership?.id)}
                    onChange={(e) =>
                      setNewUserData((data) => {
                        if (e)
                          return {
                            ...data,
                            membership: { id: e?.value },
                          };
                        else
                          return {
                            ...data,
                          };
                      })
                    }
                  />
                </Styled.UserDetailGeneralInfo>

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    멤버십 시작일
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_Regular fontSize={14} color={Colors.neutralWhite} textAlign="right">
                    {selectedUserData?.membership?.start_at ? dayjs(selectedUserData?.membership?.start_at).format('YYYY-MM-DD') : ''}
                  </Typo.IBM_Regular>
                </Styled.UserDetailGeneralInfo>

                <Styled.UserDetailGeneralInfo>
                  <Typo.IBM_SemiBold textAlign="left" fontSize={14} color={Colors.neutralWhite}>
                    멤버십 종료일
                  </Typo.IBM_SemiBold>
                  <Typo.IBM_Regular fontSize={14} color={Colors.neutralWhite} textAlign="right">
                    {selectedUserData?.membership?.end_at ? dayjs(selectedUserData?.membership?.end_at).format('YYYY-MM-DD') : ''}
                  </Typo.IBM_Regular>
                </Styled.UserDetailGeneralInfo>

                <Common.SizedBoxH height={20} />

                <Common.FlexRow width="100%" justifyContent="center" alignItems="center">
                  <Common.RoundedFilledButton width={100} height={40} backgroundColor={Colors.primary500} onClick={() => {}}>
                    <Typo.IBM_Regular fontSize={14} color={Colors.neutralWhite}>
                      계정 삭제
                    </Typo.IBM_Regular>
                  </Common.RoundedFilledButton>
                  <Common.SizedBoxW width={20} />
                  <Common.RoundedFilledButton
                    width={100}
                    height={40}
                    backgroundColor={Colors.primary100}
                    disabled={JSON.stringify(newUserData) === JSON.stringify(selectedUserData)}
                    style={{
                      opacity: JSON.stringify(newUserData) === JSON.stringify(selectedUserData) ? 0.2 : 1,
                    }}
                    onClick={() => modifyUserData()}
                  >
                    <Typo.IBM_Regular fontSize={14} color={Colors.neutralWhite}>
                      정보 수정
                    </Typo.IBM_Regular>
                  </Common.RoundedFilledButton>
                </Common.FlexRow>
              </Styled.UserDetail>
            </Common.ModalBackground>
          )}
        </>
      ) : (
        <AuthFirst />
      )}
    </>
  );
}

const MembershipString: { [key in MembershipType]: string } = {
  annually: '연간',
  monthly: '월간',
  permanent: '평생',
};

function UserInfo(props: { data: FirebaseUserDto; onDetail: () => void }): ReactElement {
  const { uid, email, providerData } = props.data;
  const onDetail = props.onDetail;

  const [userData, setUserData] = useState<UserDto | null>(null);

  useEffect(() => {
    const dbRef = ref(db);
    (async () => {
      try {
        const snapshot = await get(child(dbRef, `users/${uid}`));
        if (snapshot.exists()) {
          setUserData(snapshot.val());
        }
      } catch (error) {
        console.log(error);
        alert('에러 발생');
      }
    })();
  }, []);

  return (
    <Styled.UserInfoGrid>
      <Typo.IBM_Regular fontSize={14} textAlign="left" color={Colors.neutralWhite}>
        {email}
      </Typo.IBM_Regular>
      <Common.FlexRow alignItems="center">
        {providerData.map((e) => (
          <Common.SizedImage src={ProviderIcons[e.providerId]} width={20} height={20} key={uid + e.providerId} />
        ))}
      </Common.FlexRow>
      <Typo.IBM_Regular fontSize={14} textAlign="left" color={Colors.neutralWhite}>
        {userData?.username}
      </Typo.IBM_Regular>
      <Typo.IBM_Regular fontSize={14} textAlign="left" color={Colors.neutralWhite}>
        {userData && userData.membership && userData.membership.id && MembershipString[userData.membership.id]}
      </Typo.IBM_Regular>
      <Typo.IBM_Regular fontSize={14} textAlign="left" color={Colors.neutralWhite}>
        {userData?.point}
      </Typo.IBM_Regular>
      <Typo.IBM_Regular fontSize={14} textAlign="left" color={Colors.neutralWhite}>
        {userData?.level}
      </Typo.IBM_Regular>
      <Typo.IBM_Regular fontSize={14} textAlign="left" color={Colors.neutralWhite}>
        {uid}
      </Typo.IBM_Regular>
      {userData?.discord ? (
        <Common.FlexRow alignItems="center" justifyContent="center">
          <Common.NoOpacityButton
            onClick={() => {
              window.open(`https://discordapp.com/users/${userData.discord}`, '_blank');
            }}
          >
            <Common.SizedImage width={30} height={30} src={Icons.Discord} objectFit="contain" />
          </Common.NoOpacityButton>
        </Common.FlexRow>
      ) : (
        <Common.SizedBox width={30} height={30} />
      )}

      <Common.RoundedFilledButton width={80} height={25} backgroundColor={Colors.neutralWhite} onClick={onDetail}>
        <Typo.IBM_Regular fontSize={14} textAlign="center" color={Colors.neutralBlack}>
          상세 보기
        </Typo.IBM_Regular>
      </Common.RoundedFilledButton>
    </Styled.UserInfoGrid>
  );
}

export default ManageUsers;
