import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyAYcaXkGfXhPl7NDhnCeBHpQD_NFtg48JI',
  authDomain: 'nomadinseoul.com',
  databaseURL: 'https://nomad-in-seoul-default-rtdb.firebaseio.com',
  projectId: 'nomad-in-seoul',
  storageBucket: 'nomad-in-seoul.appspot.com',
  messagingSenderId: '492099616072',
  appId: '1:492099616072:web:fbdd0d6d2652c33ce7c77d',
  measurementId: 'G-8EHD5RHVFP',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = getStorage(app);
