import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${Colors.neutralWhite};
  z-index: 100;
`;

export const Viewport = styled.div`
  width: 1440px;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 25px;
`;
