import styled, { createGlobalStyle } from 'styled-components';
import { Colors } from 'common';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  background-color: ${Colors.secondary300};
  padding: 80px 0 0 0;
  min-height: 100vh;
`;

export const Viewport = styled.div`
  width: 1440px;
  height: 100%;
  padding: 40px 60px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const EventInfoGrid = styled.div`
  height: 30px;
  display: grid;
  // title author date
  grid-template-columns: 800px auto 200px;
  align-items: center;
  background-color: transparent;
`;
