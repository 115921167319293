import Header from 'components/header';
import { FirebaseAuthContext } from 'context/firebaseAuthContext';
import React, { ReactElement, useContext } from 'react';
import * as Styled from './main.style';

function Main(): ReactElement {
  const user = useContext(FirebaseAuthContext);

  return (
    <Styled.Container>
      <Header />
    </Styled.Container>
  );
}

export default Main;
