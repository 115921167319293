import { addParameterToURL } from './utils';
import axios from 'axios';
import { getIdToken, User } from 'firebase/auth';

const Host = 'https://us-central1-nomad-in-seoul.cloudfunctions.net';

export async function getUsers(
  user: User,
  params: { limit?: number; nextPageToken?: string },
): Promise<{ users: FirebaseUserDto[]; pageToken?: string }> {
  const { limit, nextPageToken } = params;

  try {
    const token = await getIdToken(user);

    let url = `${Host}/admin/users`;

    if (limit) url = addParameterToURL(url, 'limit', limit.toString());
    if (nextPageToken) url = addParameterToURL(url, 'nextPageToken', nextPageToken);

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) throw response.status;

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getUser(user: User, param: string): Promise<FirebaseUserDto> {
  try {
    const token = await getIdToken(user);

    let url = `${Host}/admin/user`;
    if (param) url = addParameterToURL(url, 'param', param);

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) throw response.status;

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function modifyUser(user: User, params: { uid: string; data: UserDto }): Promise<UserDto> {
  try {
    const token = await getIdToken(user);

    let url = `${Host}/admin/user/${params.uid}`;

    const response = await axios.patch(url, params.data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) throw response.status;

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function setDiscordAccount(user: User, params: { code: string; redirectUri: string }): Promise<any> {
  const { code, redirectUri } = params;

  try {
    const token = await getIdToken(user);

    const response = await axios.post(
      `${Host}/discord/${user.uid}`,
      {
        code: code,
        redirect_uri: redirectUri,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status !== 201) throw response.status;

    return response.data;
  } catch (error) {
    throw error;
  }
}
