import styled from 'styled-components';
import Colors from './colors';

export const SizedBoxW = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  height: 100%;
`;

export const SizedBoxH = styled.div<{ height: number }>`
  width: 100%;
  height: ${(props) => props.height}px;
`;

export const SizedBox = styled.div<{ width: number | string; height: number | string }>`
  width: ${(props) => (props.width && typeof props.width === 'number' ? `${props.width}px` : props.width)};
  height: ${(props) => (props.height && typeof props.height === 'number' ? `${props.height}px` : props.height)};
`;

export const FlexRow = styled.div<{ width?: number | string; height?: number | string; alignItems?: string; justifyContent?: string }>`
  display: flex;
  width: ${(props) => (props.width && typeof props.width === 'number' ? `${props.width}px` : props.width)};
  height: ${(props) => (props.height && typeof props.height === 'number' ? `${props.height}px` : props.height)};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent}`};
`;

export const FlexColumn = styled.div<{ width?: number | string; height?: number | string; alignItems?: string; justifyContent?: string }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width && typeof props.width === 'number' ? `${props.width}px` : props.width)};
  height: ${(props) => (props.height && typeof props.height === 'number' ? `${props.height}px` : props.height)};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent}`};
`;

export const Fill = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.color};
`;

export const Circle = styled.div<{ width: number; height: number; backgroundColor?: string }>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : Colors.neutralWhite)};
  border-radius: 50%;
  overflow: hidden;
`;

export const ModalBackground = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: rgba(38, 40, 44, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RoundedFilledInput = styled.input<{
  width?: number | string;
  height?: number | string;
  color?: string;
  backgroundColor?: string;
  fontSize?: number;
  textAlign?: string;
}>`
  width: ${(props) => (props.width && typeof props.width === 'number' ? `${props.width}px` : props.width)};
  height: ${(props) => (props.height && typeof props.height === 'number' ? `${props.height}px` : props.height)};

  border: none;
  padding: 12px 35px;

  background: ${(props) => (props.backgroundColor ? props.backgroundColor : Colors.neutralWhite)};
  box-shadow: 0px 2px 10px rgba(90, 90, 90, 0.2);
  border-radius: 27.5px;

  color: ${(props) => (props.color ? props.color : Colors.neutralBlack)};
  font-family: 'IBMPlexSansKR';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const RoundedFilledButton = styled.button<{
  width?: number | string;
  height?: number | string;
  backgroundColor?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0;
  border: none;

  width: ${(props) => (props.width && typeof props.width === 'number' ? `${props.width}px` : props.width)};
  height: ${(props) => (props.height && typeof props.height === 'number' ? `${props.height}px` : props.height)};

  box-shadow: 0px 2px 10px rgba(90, 90, 90, 0.2);
  border-radius: 27.5px;

  background: ${(props) => (props.backgroundColor ? props.backgroundColor : Colors.primary500)};
`;

export const RoundedFilled = styled.div<{
  width?: number | string;
  height?: number | string;
  color?: string;
  backgroundColor?: string;
}>`
  padding: 0 20px;
  border: none;

  width: ${(props) => (props.width && typeof props.width === 'number' ? `${props.width}px` : props.width)};
  height: ${(props) => (props.height && typeof props.height === 'number' ? `${props.height}px` : props.height)};

  box-shadow: 0px 2px 10px rgba(90, 90, 90, 0.2);
  border-radius: 27.5px;

  color: ${(props) => (props.color ? props.color : Colors.neutralWhite)};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : Colors.primary500)};
`;

export const NoOpacityButton = styled.button<{
  width?: number | string;
  height?: number | string;
}>`
  padding: 0;
  border: none;
  background-color: transparent;

  width: ${(props) => (props.width && typeof props.width === 'number' ? `${props.width}px` : props.width)};
  height: ${(props) => (props.height && typeof props.height === 'number' ? `${props.height}px` : props.height)};
`;

export const NoOpacityInput = styled.input<{
  width?: number | string;
  height?: number | string;
  color?: string;
  fontSize?: number;
  textAlign?: string;
}>`
  padding: 0;
  border: none;
  background-color: transparent;

  width: ${(props) => (props.width && typeof props.width === 'number' ? `${props.width}px` : props.width)};
  height: ${(props) => (props.height && typeof props.height === 'number' ? `${props.height}px` : props.height)};

  color: ${(props) => (props.color ? props.color : Colors.neutralBlack)};
  font-family: 'IBMPlexSansKR';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 14)}px;
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
`;

export const Span = styled.span`
  margin: auto;
`;

export const SizedImage = styled.img<{ width?: number; height?: number; objectFit?: string }>`
  ${(props) => props.width && `width: ${props.width}px`};
  ${(props) => props.height && `height: ${props.height}px`};
  ${(props) => props.objectFit && `object-fit: ${props.objectFit}`};
`;
