// FirebaseAuthContext.tsx
import * as React from 'react';
import { User } from 'firebase/auth';
import { auth } from 'services/firebase';

const FirebaseAuthContext = React.createContext<User | null | undefined>(undefined);

const FirebaseAuthProvider: React.FC<any> = ({ children }) => {
  const [user, setUser] = React.useState<User | null | undefined>(undefined);

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setUser);
    return unsubscribe;
  }, []);

  return <FirebaseAuthContext.Provider value={user}>{children}</FirebaseAuthContext.Provider>;
};

export { FirebaseAuthProvider, FirebaseAuthContext };
